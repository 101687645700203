<template>
  <CardToolbar
    v-loading="loading"
    class="crawled-artists"
    title="Previous Crawls"
    description="Crawls history"
    :show-toolbar-buttons="true"
  >
    <template v-slot:toolbar>
      <CardHeaderButtonAI @buttonClicked="refreshData"
        >Refresh</CardHeaderButtonAI
      >
      <CardHeaderButtonAI @buttonClicked="exportData"
        >Export</CardHeaderButtonAI
      >
      <CardHeaderButtonAI
        solid
        :disabled="!selectedArtists.length"
        @buttonClicked="addBatchToCRM"
        >+CRM</CardHeaderButtonAI
      >
      <CardHeaderButtonAI
        solid
        :disabled="!selectedArtists.length"
        @buttonClicked="removeBatchFromCRM"
        >Delete</CardHeaderButtonAI
      >
      <!--      <CardHeaderButtonAI @buttonClicked="refresh">Refresh</CardHeaderButtonAI>-->
    </template>
    <template v-slot:body>
      <a
        hidden
        :href="hrefDownloadLink"
        ref="exportButtonRef"
        download="Artists.csv"
        >Download</a
      >
      <el-table
        style="width: 100%"
        row-key="id"
        :fit="true"
        table-layout="fixed"
        :data="discogsSearchHistory"
      >
        <el-table-column label="" fixed>
          <template #default="scope">
            <div class="d-flex justify-content-center align-items-center">
              <el-avatar
                @click="
                  scope.row.artist && scope.row.artist.uuid
                    ? openArtistProfileDrawer(scope.row.artist.uuid)
                    : () => {}
                "
                :class="[
                  {
                    'cursor-pointer': scope.row.artist && scope.row.artist.uuid,
                  },
                ]"
                class="my-2"
                :size="35"
                :src="
                  scope.row.discogsArtist && scope.row.discogsArtist.avatar
                    ? scope.row.discogsArtist.avatar
                    : '/media/avatars/blank.png'
                "
              ></el-avatar>
            </div>
          </template>
        </el-table-column>
        <el-table-column sortable label="Name" width="220">
          <template #default="scope">
            {{
              scope.row.artist && scope.row.artist.name
                ? scope.row.artist.name
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column fixed sortable label="Real name" width="220">
          <template #default="scope">
            {{
              scope.row.discogsArtist && scope.row.discogsArtist.realName
                ? scope.row.discogsArtist.realName
                : ""
            }}
          </template>
        </el-table-column>

        <!--        <el-table-column width="200" label="Webiste"-->
        <!--          ><template #default="scope"-->
        <!--            ><div-->
        <!--              v-html="-->
        <!--                getLinkFromDiscogArtistObject(scope.row.discogsArtist, 'name')-->
        <!--              "-->
        <!--            ></div></template-->
        <!--        ></el-table-column>-->
        <el-table-column width="200" label="Email">
          <template #default="scope">
            <span
              v-if="
                scope.row.scrapedArtist &&
                scope.row.scrapedArtist.artistSites &&
                scope.row.scrapedArtist.artistSites.length > 0
              "
            >
              <div
                class="mb-0"
                v-for="(scrap_artist_site, index) in scope.row.scrapedArtist
                  .artistSites"
                :key="index"
              >
                <div
                  v-if="
                    scrap_artist_site.artistSitesEmails &&
                    scrap_artist_site.artistSitesEmails.length > 0
                  "
                >
                  <ul
                    class="mb-0"
                    v-for="(
                      scrap_artist_email, index
                    ) in scrap_artist_site.artistSitesEmails"
                    :key="index"
                  >
                    <a :href="'mailto:' + scrap_artist_email.email">
                      <li>
                        {{ scrap_artist_email ? scrap_artist_email.email : "" }}
                      </li>
                    </a>
                  </ul>
                </div>
              </div>
            </span>
          </template>
        </el-table-column>
        <el-table-column width="200" label="Discogs Sites">
          <template #default="scope">
            <span v-if="scope.row.discogsArtist">
              <ul
                class="mb-0"
                v-for="(discog_artist_site, index) in scope.row.discogsArtist
                  .discogArtistSites"
                :key="index"
              >
                <a
                  :href="
                    discog_artist_site && discog_artist_site.url
                      ? discog_artist_site.url
                      : ''
                  "
                  target="_blank"
                >
                  <li>
                    {{
                      discog_artist_site &&
                      discog_artist_site.sites &&
                      discog_artist_site.sites.nameOnDiscog
                        ? discog_artist_site.sites.nameOnDiscog
                        : ""
                    }}
                  </li>
                </a>
              </ul>
            </span>
          </template>
        </el-table-column>
        <el-table-column width="200" label="Google Sites">
          <template #default="scope">
            <span
              v-if="
                scope.row.scrapedArtist && scope.row.scrapedArtist.artistSites
              "
            >
              <ul
                class="mb-0"
                v-for="(scrap_artist, index) in scope.row.scrapedArtist
                  .artistSites"
                :key="index"
              >
                <a :href="scrap_artist ? scrap_artist.url : ''" target="_blank">
                  <li>
                    {{
                      scrap_artist &&
                      scrap_artist.sites &&
                      scrap_artist.sites.nameOnGoogle
                        ? scrap_artist.sites.nameOnGoogle
                        : ""
                    }}
                  </li>
                </a>
              </ul>
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" width="67" label="Action"
          ><template #default="scope"
            ><ArtistMenuComponent
              v-if="
                (scope.row.artist && scope.row.artist.name) ||
                (scope.row.discogsArtist && scope.row.discogsArtist.realName)
              "
              :artist="{
                name:
                  scope.row.artist && scope.row.artist.name
                    ? scope.row.artist.name
                    : scope.row.discogsArtist &&
                      scope.row.discogsArtist.realName
                    ? scope.row.discogsArtist.realName
                    : '',
                uuid:
                  scope.row.artist && scope.row.artist.uuid
                    ? scope.row.artist.uuid
                    : null,
              }"
              :buttons="[
                'analytics',
                'discover',
                'crawl',
                scope.row.artist && 'crm',
                scope.row.artist && 'artistDetails',
              ]"
            ></ArtistMenuComponent></template
        ></el-table-column>
        <el-table-column align="center" fixed="right" width="67" label="Delete"
          ><template #default="scope"
            ><button
              @click="removeArtistFromSearchedHistory(scope.row.id)"
              class="
                btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                el-dropdown-link
              "
            >
              <i class="bi bi-x fs-3"></i>
            </button> </template
        ></el-table-column>
        <el-table-column
          :key="checkBoxKey"
          fixed="right"
          width="65"
          label="Select"
        >
          <template #default="scope">
            <el-checkbox
              @change="checkBoxValueChanged($event, scope.row.id)"
              label=""
              size="large"
            ></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt-7 mb-7"
        v-model:currentPage="paginationObject.offset"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="paginationObject.limit"
        layout="sizes, prev, pager, next"
        :total="paginationObject.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <!--      <CommonPagination @paginate="paginate" :totalRecords="1000"/>-->
    </template>
  </CardToolbar>
</template>

<script>
import CardToolbar from "../../common/components/CardToolbar";
import CardHeaderButtonAI from "../../aiBetaBench/components/CardHeaderButtonAI";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
// import CommonPagination from "@/artists/components/CommonPagination";
import {
  deleteBatchDiscogsSearchHistoryId,
  deleteDiscogsSearchHistoryById,
} from "../../api/user.api";
import ArtistMenuComponent from "../../artists/components/ArtistMenuComponent";
import { ElNotification } from "element-plus";
import { addBatchArtistToCRM } from "../../api/hubspot";

export default {
  name: "CrawlerSavedHistory",
  components: { ArtistMenuComponent, CardHeaderButtonAI, CardToolbar },
  setup() {
    const store = useStore();
    const loading = ref(false);
    const hrefDownloadLink = ref(null);
    const exportButtonRef = ref(null);
    const selectedArtists = ref([]);
    const checkBoxKey = ref(0);
    const discogsSearchHistory = computed(() => {
      return store.getters["UserModule/getDiscogsSearchHistory"];
    });

    const paginationObject = computed(() => {
      return store.getters["UserModule/getCrawlerPagination"];
    });

    const checkBoxValueChanged = (event, id) => {
      if (event && !selectedArtists.value.includes(id)) {
        selectedArtists.value.push(id);
      } else if (!event) {
        const index = selectedArtists.value.indexOf(id);
        if (index > -1) {
          selectedArtists.value.splice(index, 1);
        }
      }
    };

    onMounted(async () => {
      loading.value = true;
      await store.dispatch("UserModule/fetchDiscogsSearchHistory");
      loading.value = false;
    });

    const refreshData = async () => {
      loading.value = true;
      await store.dispatch("UserModule/fetchDiscogsSearchHistory", true);
      loading.value = false;
    };

    const removeArtistFromSearchedHistory = async (id) => {
      loading.value = true;
      try {
        await deleteDiscogsSearchHistoryById(id);
        await store.dispatch("UserModule/fetchDiscogsSearchHistory", true);
        localStorage.setItem(
          "fetch-discogs-search-history",
          Math.random() + "discogs-search-history"
        );
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Error",
          message: "Unable to find record!",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const addBatchToCRM = async () => {
      if (selectedArtists.value.length) {
        loading.value = true;
        try {
          await addBatchArtistToCRM(selectedArtists.value);
          checkBoxKey.value++;
          selectedArtists.value = [];
          ElNotification({
            title: "Success",
            message: "Selected artists added to CRM!",
            type: "success",
          });
        } catch (e) {
          ElNotification({
            title: "Error",
            message: "Error adding to CRM, try again later",
            type: "error",
          });
        } finally {
          loading.value = false;
        }
      }
    };
    // const paginate = (val) => {
    //   console.log("pagination",val);
    // };

    const removeBatchFromCRM = async () => {
      if (selectedArtists.value.length) {
        loading.value = true;
        try {
          await deleteBatchDiscogsSearchHistoryId(selectedArtists.value);
          await store.dispatch("UserModule/fetchDiscogsSearchHistory", true);
          localStorage.setItem(
            "fetch-discogs-search-history",
            Math.random() + "discogs-search-history"
          );
          checkBoxKey.value++;
          selectedArtists.value = [];
          ElNotification({
            title: "Success",
            message: "Selected artists removed from History",
            type: "error",
          });
        } catch (e) {
          ElNotification({
            title: "Error",
            message: "Error remove artists, try again later",
            type: "error",
          });
        } finally {
          loading.value = false;
        }
      }
    };

    const getSitesObject = (discogArtistSites) => {
      var obj = {}; //create the empty output object
      discogArtistSites.forEach(function (item) {
        var key = item["sites"].name; //take the first key from every object in the array
        obj[key] = item.url; //assign the key and value to output obj
      });
      return obj;
    };

    const exportData = async () => {
      const filteredArtists = discogsSearchHistory.value.map((item) => {
        return {
          Name: item.discogsArtist ? item.discogsArtist.realName : "",
          ...getSitesObject(item.discogsArtist.discogArtistSites),
        };
      });
      const keys = Object.keys(filteredArtists[0]);
      const commaSeparatedString = [
        keys.join(","),
        filteredArtists
          .map((row) => keys.map((key) => row[key]).join(","))
          .join("\n"),
      ].join("\n");
      const csvBlob = new Blob([commaSeparatedString]);
      hrefDownloadLink.value = URL.createObjectURL(csvBlob);
      setTimeout(() => {
        exportButtonRef.value.click();
      }, 100);
    };

    const getSiteURLFromDiscogArtist = (discogObject, siteToFind) => {
      if (
        discogObject &&
        discogObject.sites_parsed_data &&
        discogObject.sites_parsed_data[siteToFind] &&
        discogObject.sites_parsed_data[siteToFind].url
      ) {
        return discogObject.sites_parsed_data[siteToFind].url;
      }
      return "";
    };

    const getLinkFromDiscogArtistObject = (discogObject, siteToFind) => {
      let responseObject = { name: "", url: "#" };
      if (
        discogObject &&
        discogObject.sites_parsed_data &&
        discogObject.sites_parsed_data[siteToFind] &&
        discogObject.sites_parsed_data[siteToFind].name &&
        discogObject.sites_parsed_data[siteToFind].url
      ) {
        responseObject.name = discogObject.sites_parsed_data[siteToFind].name;
        responseObject.url = discogObject.sites_parsed_data[siteToFind].url;
      }
      return `<a href="${responseObject.url}" target="_blank">${responseObject.name}</a>`;
    };

    const handleCurrentChange = async (val) => {
      try {
        loading.value = true;
        paginationObject.value.offset = val;
        store.commit(
          "UserModule/SET_CRAWLER_PAGINATION",
          paginationObject.value
        );
        await store.dispatch("UserModule/fetchDiscogsSearchHistory");
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    const handleSizeChange = async (val) => {
      try {
        loading.value = true;
        paginationObject.value.limit = val;
        store.commit(
          "UserModule/SET_CRAWLER_PAGINATION",
          paginationObject.value
        );
        await store.dispatch("UserModule/fetchDiscogsSearchHistory");
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };
    // const refresh = async () => {
    //   loading.value = true;
    //   await store.dispatch("UserModule/fetchDiscogsSearchHistory", true);
    //   loading.value = false;
    // };

    return {
      discogsSearchHistory,
      loading,
      hrefDownloadLink,
      exportButtonRef,
      selectedArtists,
      checkBoxKey,
      paginationObject,
      checkBoxValueChanged,
      exportData,
      getLinkFromDiscogArtistObject,
      removeArtistFromSearchedHistory,
      addBatchToCRM,
      removeBatchFromCRM,
      refreshData,
      handleCurrentChange,
      handleSizeChange,
      // paginate,
    };
  },
};
</script>

<style lang="scss"></style>
